* {
    font-size: inherit;
}

.sticky-top {
    z-index: 1;

}

.requiredField::after {
    content: "🟥";
    font-size: 7pt;
    margin-right: 0.3rem;
}

.bullet::before {
    content: "▪️";
}

.btn-group-vertical>button {
    margin-bottom: 10px;
    text-align: right;
}

.btn-info,
.bg-info {
    background-color: #17a2b8 !important;
}

.btn-info.active {
    background-color: #117a8b !important;
}

.bgLight {
    background-color: #EEF2FF !important;
}

.text-primary {
    color: #3E6D9C !important;
}

.btn-primary {
    color: #fff;
    background-color: #3E6D9C;
    border-color: #3E6D9C;
}

.border-primary {
    border-color: #3E6D9C !important;
}

.primarySelected {
    border: 0 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-color: #3E6D9C !important;
    border-width: 3px !important;
}

.bgLightGray {
    background-color: #AEBDCA !important;
}

.bgToday {
    background-color: #C36839;
}

/* ul.list-group li:nth-of-type(odd) {
    background-color: #d6d8db !important;
}

ul.list-group li:hover {
    background-color: #748DA6 !important;
} */
.list-group-item {
    padding: 15px 20px; /* Increase padding for better spacing */
    border: none; /* Remove the default border */
    margin-bottom: 10px; /* Add space between items */
    background-color: #ffffff; /* White background for each item */
    border-radius: 8px; /* Rounded corners for a softer look */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
}

.list-group-item:last-child {
    margin-bottom: 0; /* Remove bottom margin from the last item */
}

.list-group-item:hover {
    transform: translateY(-3px); /* Slight lift on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
    background-color: #f8f9fa; /* Slightly different background on hover */
}

.list-group-item:first-child {
    border-top-left-radius: 8px; /* Rounded top corners */
    border-top-right-radius: 8px;
}

.list-group-item:last-child {
    border-bottom-left-radius: 8px; /* Rounded bottom corners */
    border-bottom-right-radius: 8px;
}

.list-group-item + .list-group-item {
    margin-top: 10px; /* Consistent spacing between items */
}


.image-container {
    position: relative;
    padding-top: 100%;
    /* 1:1 aspect ratio; for a landscape orientation you might use padding-top: 66.66%; for a 3:2 ratio */
    overflow: hidden;
    /* Ensures image does not escape container */
}

.image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.rtl-form-check-inline {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

@media screen and (min-width: 1400px) {
    .w-lg-25 {
        width: 25% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }

    .w-lg-90 {
        width: 90% !important;
    }

    .width-third {
        flex: 0 0 calc(33.333% - 1rem);
    }
}

@media screen and (min-width: 576px) {
    .hideOnLargeScreen {
        display: none;
    }

    .before {
        flex: 0 0 15%;
    }

    .w-md-10 {
        width: 10% !important;
    }
    
    .w-md-20 {
        width: 20% !important;
    }

    .w-md-25 {
        width: 25% !important;
    }

    .w-md-40 {
        width: 40% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-45 {
        width: 45% !important;
    }

    .h-md-60 {
        height: 60% !important;
    }

    .h-md-70 {
        height: 70% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-90 {
        width: 90% !important;
    }

    .max-w-select {
        max-width: 40%;
    }

    .width-third {
        flex: 0 0 calc(33.333% - 1rem);
    }

    .width-quater {
        flex: 0 0 calc(25.0% - 1rem);
    }

    .width-fifth {
        flex: 0 0 calc(20.0% - 1rem);
    }
    
    .width-fifteen-percent {
        flex: 0 0 calc(15.0% - 1rem);
    }
    .width-twelve-percent {
        flex: 0 0 calc(12.0% - 1rem);
    }
}

@media screen and (max-width: 576px) {
    .hideOnSmallScreen {
        display: none;
    }  

    .btn-group-vertical {
        width: 100% !important;
    }

    .before {
        flex: 0 0 15%;
    }

    .mt-sm-5pc {
        margin-top: 5% !important;
    }

    
}