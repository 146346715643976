.rbc-calendar {
    direction: rtl;
}

.rbc-event,
.rbc-header,
.rbc-day-slot .rbc-background-event {
    text-align: right !important;
}

/* Hide the original text (day) inside the button, make the click on all rbc-header */
.rbc-month-view .rbc-button-link {
    color: transparent;
    width: 100%;
}

/*hide Saturday */
/*try - week - ,
.rbc-time-view .rbc-header:nth-child(7) ,
.rbc-time-view .rbc-day-bg:nth-child(7) ,
.rbc-time-view .rbc-row-content:nth-child(7) ,
.rbc-time-view .rbc-time-column:nth-child(8) */

/* .rbc-month-view .rbc-header:nth-child(7) ,
.rbc-month-view .rbc-date-cell:nth-child(7) ,
.rbc-month-view .rbc-day-bg:nth-child(7)
 {
    display: none;
} */

.rbc-today {
    background-color: #E9B384 !important;
}

.bgBtn {
    background-color: #577D86 !important;
    color: white !important;
}

.bgDate {
    background-color: #BFCCB5 !important;
}

/* .rbc-month-view .rbc-event-allday, */
/* .rbc-month-view .event-container, */
/*,
.rbc-time-view .rbc-event-allday,
.rbc-time-view .event-container, 
.rbc-time-view .rbc-event */
.rbc-month-view .rbc-event
    {
    background-color: transparent !important;
    border: 0;
}