.list-item-hover:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.list-item-hover:hover .icon {
    background-color: #fff;
    color: #007bff;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.overlay-effect {
    transition: opacity 0.3s ease;
}

.list-item-hover:hover .overlay-effect {
    opacity: 0.2; /* Increase opacity to make the overlay more prominent on hover */
}

.list-item-hover:hover a {
    color: #007bff;
    transition: color 0.3s ease;
}

.icon {
    font-size: 30px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.text-decoration-none:hover {
    color: #007bff;
}

.media-body h5 {
    font-size: 1.25rem;
}

@media (min-width: 768px) {
    .col-md-6 {
        padding-left: 15px;
        padding-right: 15px;
    }
}
